import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { theme } from '../../domain/theme';
import { SmartLink } from '../SmartLink';
import { Image } from '../Image';
import { t } from '../../domain/services/configService';
import { cardTagSponsored } from '../Styles/card';
import { dateService } from '../../domain/services/dateService';
import { VideoSchema } from '../responsive/atoms/VideoSchema';
import { getVideoSchemaDataFromVideoArticle } from '../../domain/services/schemaService/types/videoObjectType';

const styles = {
  cardSubtitle: css`
    ${theme.font.family('regularWeb')};
    font-size: ${theme.font.size('s')};
    font-weight: ${theme.font.weight('base')};
    letter-spacing: ${theme.letterSpacing('s')};
    margin-bottom: ${theme.spacing('xs2')};
    ${theme.mq.small_desktop} {
      font-size: ${theme.font.size('base')};
    }
    text-align: left;
  `,
  carouselCard: css`
    position: relative;
    display: inline-block;
    padding: ${theme.spacing('xs3')};
    width: 25%;
    vertical-align: top;
    position: relative;
    white-space: initial;
    ${theme.mq.tablet} {
      padding: ${theme.spacing('xs3')};
    }
    ${theme.mq.large_desktop} {
      padding: ${theme.spacing('xs2')};
    }
    img {
      transform-origin: 65% 75%;
      transition: transform 1s, filter 0.5s ease-out;
      &:hover {
        transform: scale(1.2);
      }
    }
  `,
  sponsoredContent: css`
    position: absolute;
    left: 15%;
    right: 15%;
    bottom: 35%;
    text-transform: uppercase;
  `,
  cardTitle: css`
    ${theme.font.family('regularWeb')};
    font-size: ${theme.font.size('xs2')};
    font-weight: ${theme.font.weight('l')};
    letter-spacing: ${theme.letterSpacing('s')};
    hyphens: auto;
    bottom: 0;
    left: 0;
    margin: ${theme.spacing.parse('$xs3 0')};
    text-align: left;
  `,
  carouselCardImageWrapper: css`
    position: relative;
  `,
  carouselCardImageGradient: css`
    top: 0;
    width: 100%;
    z-index: 25;
    height: 100%;
    position: absolute;
    left: 0;
    background: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(#000),
      color-stop(60%, transparent)
    );
    background: -o-linear-gradient(bottom, #000, transparent 60%);
    background: linear-gradient(0deg, #000, transparent 60%);
  `,
  carouselCardActive: css`
    :after {
      background-color: #fc0;
      bottom: 0;
      content: '';
      display: block;
      height: 5px;
      position: absolute;
      width: 100%;
      z-index: 4;
      -webkit-transition: background-color 0.25s linear;
      -o-transition: background-color 0.25s linear;
      transition: background-color 0.25s linear;
    }
  `,
  carouselCardBottomRightInfo: css`
    color: #fff;
    ${theme.font.family('regularWeb')};
    font-size: ${theme.font.size('xs2')};
    font-weight: ${theme.font.weight('base')};
    position: absolute;
    bottom: 0;
    right: 0;
    margin: ${theme.spacing.parse('$xs2 $xs3')};
    z-index: 30;
  `,
  carouselCardBottomLeftInfo: css`
    color: #fff;
    text-transform: uppercase;
    ${theme.font.family('regularWeb')};
    font-size: ${theme.font.size('xs2')};
    font-weight: ${theme.font.weight('l')};
    position: absolute;
    bottom: 0;
    left: 0;
    margin: ${theme.spacing.parse('$xs2 $xs3')};
    z-index: 30;
  `
};

const VideoCarouselCard = ({
  data,
  fontColor,
  onClick,
  index,
  active,
  isAboveTheFold
}) => {
  const duration = data.videoRef?.entity?.duration
    ? dateService.secondsToMinutes(data.videoRef.entity.duration)
    : null;

  const { title, subtitle } = data;
  const image = data.image?.entity || data.promoImage?.[0]?.entity;
  const link = data.url?.path || '';
  const isSponsoredContent =
    data.carouselRefCard?.entity?.sponsoredTextCard &&
    data.carouselRefCard?.entity?.branded &&
    data.showExternalSponsorLabel;

  const cardStyles = {
    cardTextTheme: css`
      color: ${fontColor};
    `
  };
  return (
    <div css={styles.carouselCard}>
      <SmartLink
        to={link}
        target={data.openLinkInNewTab ? '_blank' : null}
        title={data.carouselRefCard?.entity?.promoTitle}
        native
        onClick={e => {
          onClick(index);
          e.preventDefault();
        }}
      >
        <div css={[cardStyles.cardTextTheme, styles.carouselCardImageWrapper]}>
          <Image
            entity={image}
            aspect="16x9"
            loading={isAboveTheFold ? 'eager' : 'lazy'}
            onClick
          />
          {duration ? (
            <div css={styles.carouselCardBottomRightInfo}>{duration}</div>
          ) : null}
          {active ? (
            <div css={styles.carouselCardBottomLeftInfo}>
              {t('Now Playing')}
            </div>
          ) : null}
          <div
            css={[
              styles.carouselCardImageGradient,
              active ? styles.carouselCardActive : null
            ]}
          />
        </div>

        {isSponsoredContent && (
          <div
            className="card__tag--sponsored"
            css={[styles.sponsoredContent, cardTagSponsored]}
          >
            {`${t('Paid content')} `}
          </div>
        )}
        {title && (
          <div css={[cardStyles.cardTextTheme, styles.cardTitle]}>{title}</div>
        )}
      </SmartLink>
      {subtitle && <div css={styles.cardSubtitle}>{subtitle}</div>}
      <VideoSchema schemaData={getVideoSchemaDataFromVideoArticle(data)} />
    </div>
  );
};

VideoCarouselCard.defaultProps = {
  active: false
};

VideoCarouselCard.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  index: PropTypes.number.isRequired,
  fontColor: PropTypes.string.isRequired,
  isAboveTheFold: PropTypes.bool.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

export default VideoCarouselCard;
